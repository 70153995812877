import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import MiniBlogRoll from '../components/MiniBlogRoll'
import logo from '../img/logo.svg';
import indexMe from '../img/index-me.jpg';
import phone from '../img/contact_phone.svg'
import email from '../img/contact_email.svg'
import Testimonials from '../components/Testimonials'
import cert1 from '../img/cert1.png';
import cert2 from '../img/cert2.png';
import left from '../img/arrow-left-white.svg'
import right from '../img/arrow-right-white.svg'


class IndexPageTemplate extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      showGallery: false,
      currentGalleryIndex: 0
    }

    this.certs = new Array(cert1, cert2);

  }
  
  showGallery()
  {
    this.setState({
      showGallery: true,
    });
  }

  hideGallery()
  {
    this.setState({
      showGallery: false,
    });
  }

  rightButtonClicked()
{
  let index = this.state.currentGalleryIndex >= this.certs.length - 1 ? 0 : this.state.currentGalleryIndex + 1;

  this.setState({
    currentGalleryIndex: index
  });
}

leftButtonClicked()
{
  let index = this.state.currentGalleryIndex <= 0 ? this.certs.length - 1 : this.state.currentGalleryIndex - 1;
  
  this.setState({
    currentGalleryIndex: index
  });
}

  render() {

  const image = this.props.image
  const title = this.props.title
  const heading = this.props.heading
  const subheading = this.props.subheading
  const description = this.props.description

return (
  <div>
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image?.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `center center`,
        backgroundAttachment: `fixed`,
        position: `absolute`,
        top: `0`
      }}
    >
      <div className="jumbo-overlay jumbo-overlay-bottom"></div>
      <div className="jumbo-overlay jumbo-overlay-top"></div>

      <div
        style={{
          display: 'flex',
          height: '150px',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column',
          position: 'absolute',
          alignItems: 'center'
        }}
      >
        <img src={logo} alt="Krystyna Bronowicka" style={{ width: '80%' }} />
      </div>
    </div>
    <section className="section section--gradient" style={{marginTop: '380px'}}>
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="columns">
                  <div className="column is-12">
                    <div className="main-card-container">
                      <div className="card-back-frame card-back-frame-pink only-on-mobile"></div>
                      <div className="card-back-frame card-back-frame-blue only-on-mobile"></div>
                      <div className="main-card">
                      <div className="main-card-text">
                        <h3 className="has-text-weight-semibold is-size-3 text-pink">
                          {heading}
                        </h3>
                        <p>
                        Jestem coachem i pracuję z kobietami które dojrzały do przeprowadzenia zmian w konkretnych obszarach swojego życia, takich zmian aby ich życie było pełne pasji i satysfakcji. Z kobietami które były już w różnych rolach "dla kogoś", a teraz chcą być w roli "dla siebie samej" i chcą rozpocząć ten nowy rozdział na własnych zasadach. </p>
<p>
Na początku pomogę Ci rozeznać się w Twojej obecnej sytuacji, stwierdzimy  co jest  ok, a co nie. Z czego jesteś zadowolona mniej, bardziej lub wcale.
Porozmawiamy o Twojej potrzebie zmiany, odpowiesz sobie na pytanie, jak chcesz żeby było? Czego dokładnie pragniesz?
Rozpoznamy przeszkody, które stają Ci na drodze, nazwiemy je i zaczniemy nad nimi pracować. Wykorzystamy do tego twoje zasoby i mocne strony  i odkryjemy co jeszcze możesz  zrobić,  żeby dojść tam gdzie chcesz.
</p>
<p>
Stworzymy plan działania, podzielimy go na mniejsze części, abyś mogła efektywnie wdrożyć go w życie.
Ja Cię w tym wszystkim wesprę, jednocześnie oczekując od Ciebie zaangażowania przy  podejmowaniu konkretnych działań.
Będziesz działać dotąd, dopóki nie osiągniesz tego co sobie założyłaś.
                        </p>
                        <Link className="btn" to="/kontakt" style={{ width: '100%', marginTop: '1em'}}>
                          Umów się na bezpłatną konsultację  
                        </Link>
                      </div>
                    </div>
                      <div className="main-card is-hidden-mobile">
                        <div className="card-back-frame card-back-frame-pink"></div>
                        <div className="card-back-frame card-back-frame-blue"></div>
                        <img className="card-image" src={indexMe} style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center'}} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="column is-12" style={{padding: '0', marginTop: '100px'}}>
                  <h3 className="has-text-weight-semibold has-text-centered is-size-3 text-blue" style={{marginBottom: '80px'}}>
                    Najnowsze na blogu
                  </h3>
                  <MiniBlogRoll />
                  {/* <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/blog">
                      Read more
                    </Link>
                  </div> */}
                </div>
                
              </div>
            </div>
            
          </div>
        </div>
        <Testimonials />
        <div className="section">
          <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="content">
              <div className="columns">
                <div className="column is-12">
                  <h3 className="has-text-weight-semibold is-size-3 has-text-centered" style={{marginTop: '50px'}}>
                    Skontaktuj się ze mną
                  </h3>
                  <p className="has-text-centered">
                    Jeśli potrzebujesz wsparcia, jesteś na etapie startu lub po prostu potrzebujesz pomocy w realizacji celów, zapraszam na bezpłatną, indywidualną konsultację. Sprawdźmy w czym mogę Ci pomóc.
                  </p>
                </div>
              </div>
              <div className="columns">
                <div className="column is-6 has-text-centered contact-type">
                  <img src={phone} />
                  <h4>
                    +48 729 323 563 
                  </h4>
                  <p>
                    Poniedziałek - Piątek <br/>
                    16:00 - 20:00
                  </p>
                </div>
                <div className="column is-6 has-text-centered contact-type">
                  <img src={email} />
                  <h4>
                    krystynabronowicka@wp.pl
                  </h4>
                  <p>
                    Skontaktuj się ze mną bezpośrednio, lub użyj <Link to="/kontakt"> formularza </Link> w zakładce kontakt
                  </p>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </section>
    { this.state.showGallery ? 
    <div className="cert-gallery">
      <div className="cert-gallery-background" onClick={() => this.hideGallery()}> </div>
     <img src={this.certs[this.state.currentGalleryIndex]} onClick={() => this.hideGallery()}></img>
     <div className="gallery-arrow gallery-arrow-left" onClick={() => this.leftButtonClicked()}><img src={left} /></div>
     <div className="gallery-arrow gallery-arrow-right" onClick={() => this.rightButtonClicked()} ><img src={right} /></div>
    </div>
      : null }
    </div>
)
}

}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout mainPage={true}>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        description
      }
    }
  }
`

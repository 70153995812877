import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import left from '../img/arrow-left.svg'
import right from '../img/arrow-right.svg'
import quotes from '../img/quotes.svg'

class Testimonials extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      currentIndex: 0,
    }
  }

  render() {
    const { data } = this.props
    const { edges: testimonials } = data.allMarkdownRemark
    
  return (
    <div className="testimonials" key={testimonials[this.state.currentIndex].node.id}>
      <div className="testimonials-background" ></div>
      <div className="column is-12 testimonials-container" key={testimonials[this.state.currentIndex].node.id} style={{position: 'relative'}}>
        <div className="testimonials-arrow testimonials-arrow-left" onClick={() => this.leftButtonClicked()}><img src={left} /></div>
        <div className="column is-10 is-offset-1 has-text-centered" key={testimonials[this.state.currentIndex].node.id} style={{padding: '0rem 2rem'}}>
          <h3 className="has-text-weight-semibold has-text-centered is-size-3 text-blue" >Klienci o coachingu ze mną</h3>
          <img src={quotes} className="testimonials-quotes" />
          <p className="is-size-4">
            {testimonials[this.state.currentIndex].node.frontmatter.text}
          </p>
        </div>
        <div className="testimonials-arrow testimonials-arrow-right" onClick={() => this.rightButtonClicked()} ><img src={right} /></div>
      </div>
      <div className="testimonials-pointer">
      </div>
      <div className="testimonials-author has-text-centered">
        <h5 className="has-text-centered">{testimonials[this.state.currentIndex].node.frontmatter.author}</h5>
        <h5 className="has-text-centered" style={{opacity: '0.5'}}>{testimonials[this.state.currentIndex].node.frontmatter.role}</h5>
      </div>
    </div>
)
}

rightButtonClicked()
{
  let index = this.state.currentIndex >= this.props.data.allMarkdownRemark.edges.length - 1 ? 0 : this.state.currentIndex + 1;

  this.setState({
    currentIndex: index
  });
}

leftButtonClicked()
{
  let index = this.state.currentIndex <= 0 ? this.props.data.allMarkdownRemark.edges.length - 1 : this.state.currentIndex - 1;
  
  this.setState({
    currentIndex: index
  });
}
}

Testimonials.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query TestimonialsQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "review" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                text
                templateKey
                author
                role
              }
            }
          }
        }
      }
    `}
    render={(data) => <Testimonials data={data}/>}
  />
)
